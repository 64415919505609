import React from 'react'
import {
  Box,
  MainHeadingWithDescription
} from '@sitewerk/juraholzbau-design-system'
import { PageContext } from '../lib/sanity-types'
import { ServiceOverview } from '../components/service-overview'
import { Layout } from '../components/layout'
import { Header } from '../components/header-old'
import { ContentGrid } from '../components/content-grid'
import { BlogPosts } from '../components/blog-posts'
import { Services } from '../components/services'
import { Team } from '../components/team'
import { Projects } from '../components/projects'
import { History } from '../components/history'
import { ConfiguratorFooter } from '../components/configurator-footer'
import { RichText } from '../components/rich-text'

const Page: React.FC<{ pageContext: PageContext }> = ({
  pageContext: { settings, page, menu, quotes, services, articles, teamMembers }
}) => {
  return (
    <Layout seo={page.seo} settings={settings} menu={menu}>
      {page.template.modules.map(module => {
        switch (module._type) {
          case 'pageHeader': {
            return (
              <Header
                key={module._key}
                menu={menu}
                {...module}
                slideshow={[
                  {
                    title: module.title,
                    description: module.description,
                    image: module.bgs[0]
                  }
                ]}
              />
            )
          }

          case 'pageServiceOverview': {
            return (
              <ServiceOverview
                key={module._key}
                services={services}
                {...module}
              />
            )
          }

          case 'pageContent': {
            return (
              <MainHeadingWithDescription
                isLarge={true}
                title={module.richText[0].children[0].text}
                description={module.richText.slice(1, module.richText.length)}
              />
            )
          }

          case 'pageContentGrid': {
            return (
              <ContentGrid
                key={module._key}
                {...module}
                items={module.items.map(item => {
                  if (item._type == 'service') {
                    return {
                      _type: item._type,
                      title: item.name,
                      subtitle: 'Dienstleistung',
                      imageAsset: item.previewImage,
                      href: item.isRoot
                        ? `/dienstleistungen/${item.parentServiceSlug}/${item.slug}`
                        : `/dienstleistungen/${item.slug}`
                    }
                  }

                  if (item._type == 'article') {
                    return {
                      _type: item._type,
                      title: item.title,
                      subtitle: 'Blog',
                      imageAsset: item.previewImage,
                      href: `/blog/${item.slug}`
                    }
                  }
                })}
              />
            )
          }

          case 'pageBlogPosts': {
            return (
              <BlogPosts key={module._key} {...module} articles={articles} />
            )
          }

          case 'pageServices': {
            return <Services key={module._key} services={services} />
          }

          case 'pageTeam': {
            return (
              <Team key={module._key} {...module} teamMembers={teamMembers} />
            )
          }

          case 'pageHistory': {
            return <History key={module._key} {...module} />
          }

          case 'pageProjects': {
            return (
              <Projects key={module._key} {...module} services={services} />
            )
          }
        }
      })}

      <Box bg="white">
        <ConfiguratorFooter services={services} />
      </Box>
    </Layout>
  )
}

export default Page
