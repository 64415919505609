import React from 'react'
import { BlogPostsModule } from '../lib/sanity-types'
import {
  MainHeading,
  Box,
  SpaceChildren,
  Text,
  Flex
} from '@sitewerk/juraholzbau-design-system'
import { Article } from '../lib/sanity-types/article'
import Img from 'gatsby-image/withIEPolyfill'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import * as sanity from '../lib/sanity'
import { BasicRichText } from './basic-rich-text'
import { LearnMoreLink } from './learn-more-link'
import { FadeInContainer } from './fade-in-container'

const BlogPost: React.FC<{ article: Article }> = ({ article }) => (
  <FadeInContainer>
    <Flex alignItems="center">
      <Box
        boxShadow={0}
        height={['8rem', '12rem', '20rem']}
        width={['8rem', '12rem', '20rem']}
        flexShrink={0}
      >
        <Img
          style={{ height: '100%', width: '100%' }}
          objectFit="cover"
          fluid={getFluidGatsbyImage(
            article.previewImage.asset,
            { maxWidth: 1920 },
            sanity.config
          )}
          alt={article.previewImage.caption}
        />
      </Box>
      <Box ml={[4, 5]}>
        <Text m={0} mb={[1, null, 5]} fontSize={0} textColor="neutral.500">
          {article.datePublished}
        </Text>

        <Text
          m={0}
          mb={4}
          fontSize={[3, null, 4]}
          textColor="neutral.200"
          lineHeight={1}
          fontWeight="bold"
          maxWidth="20ch"
        >
          {article.title}
        </Text>

        <Box display={['none', null, 'block']}>
          <BasicRichText
            blocks={article.description}
            TextElement={props => {
              // @ts-ignore
              return (
                <Text
                  as="h2"
                  m={0}
                  textColor="neutral.200"
                  fontWeight={300}
                  fontSize={2}
                  lineHeight={2}
                  {...props}
                />
              )
            }}
          />
        </Box>
        <LearnMoreLink to={`/blog/${article.slug}`} mt={4}>
          Weiterlesen
        </LearnMoreLink>
      </Box>
    </Flex>
  </FadeInContainer>
)

export const BlogPosts: React.FC<BlogPostsModule & { articles: Article[] }> = ({
  articles,
  text
}) => (
  <Box mx={5}>
    <MainHeading>{text.title}</MainHeading>

    <Box maxWidth="60rem" margin="auto" mb={[5, null, null, 7]}>
      <SpaceChildren size={5} direction="top">
        {articles
          .filter(x => !x._id.startsWith('drafts.'))
          .map((article, i) => (
            <BlogPost key={i} article={article} />
          ))}
      </SpaceChildren>
    </Box>
  </Box>
)
