import React from 'react'
import {
  Container,
  MainHeading,
  Box,
  Button,
} from '@sitewerk/juraholzbau-design-system'
import { Service } from '../lib/sanity-types'
import { ContentGrid } from './content-grid'

export const Services: React.FC<{ services: Service[] }> = ({ services }) => (
  <Container>
    {services.map((service) => {
      const allServices = [service, ...service.services]
      const grids = chunk<Service>(allServices, 4)

      return (
        <Box mb={3}>
          {grids.map((s, i) => (
            <ContentGrid
              pb={3}
              key={service._id}
              text={i == 0 && { title: service.name }}
              items={s.map((subService) => ({
                _type: 'service',
                title: subService.name,
                subtitle: subService.isRoot ? '' : service.name,
                imageAsset: subService.previewImage,
                href: subService.isRoot
                  ? `/dienstleistungen/${service.slug}/`
                  : `/dienstleistungen/${service.slug}/${subService.slug}`,
              }))}
            />
          ))}
        </Box>
      )
    })}
  </Container>
)

function chunk<T>(arr: T[], n: number): T[][] {
  return arr.reduce((acc, item, i) => {
    const chunkIndex = Math.floor(i / n)

    if (!acc[chunkIndex]) {
      acc[chunkIndex] = []
    }

    acc[chunkIndex].push(item)
    return acc
  }, [])
}
