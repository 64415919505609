import React from 'react'
import { Container } from '@sitewerk/juraholzbau-design-system'
import { ContentGrid } from './content-grid'
import { TeamModule, TeamMember } from '../lib/sanity-types'

export const Team: React.FC<TeamModule & { teamMembers: TeamMember[] }> = ({
  text,
  teamMembers,
}) => {
  const grids = chunk<TeamMember>(teamMembers, 4)

  return (
    <Container mb={7}>
      {grids.map((teamMembers, i) => (
        <ContentGrid
          imagePosition="50% 15%"
          key={i}
          reverse={i % 2 !== 0}
          text={i == 0 && text}
          items={teamMembers.map((member) => ({
            _type: 'teamMember',
            title: member.name,
            subtitle: member.position,
            description: member.title,
            imageAsset: {
              caption: `Portrait ${member.name}`,
              asset: member.portraitImage,
            },
          }))}
        />
      ))}
    </Container>
  )
}

function chunk<T>(arr: T[], n: number): T[][] {
  return arr.reduce((acc, item, i) => {
    const chunkIndex = Math.floor(i / n)

    if (!acc[chunkIndex]) {
      acc[chunkIndex] = []
    }

    acc[chunkIndex].push(item)
    return acc
  }, [])
}
