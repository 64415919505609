import {
  Box,
  Container,
  Flex,
  MainHeadingWithDescription,
  styled,
  TextBase,
  theme
} from '@sitewerk/juraholzbau-design-system'
import { Link } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import React, { useEffect, useState } from 'react'
import FlipMove from 'react-flip-move'
import Select from 'react-select'
import * as sanity from '../lib/sanity'
import { Project, ProjectsModule, Service } from '../lib/sanity-types'
import { General } from './icons'
import { SvgInline } from './svg-inline'

const Heading1 = TextBase.withComponent('h3')
const Heading2 = TextBase.withComponent('h4')

const Button = styled(TextBase.withComponent('button'))({
  cursor: 'pointer',
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  '&:focus': { outline: 0 }
})

Button.defaultProps = {
  px: 3,
  py: 2,
  backgroundColor: 'transparent',
  textColor: 'neutral.200',
  fontFamily: 'default',
  fontWeight: 'bold',
  fontSize: 0
}

const SelectButton: React.FC<{
  service: Pick<Service, '_id' | 'name' | 'iconUrl'>
  selectedService: Partial<Service>
  setSelectedService: (service: Partial<Service>) => void
}> = ({ service, selectedService, setSelectedService }) => (
  <Button onClick={() => setSelectedService(service)}>
    <Box
      borderColor="currentColor"
      textColor={
        (selectedService == null && service._id == null) ||
        service._id == selectedService._id
          ? 'neutral.200'
          : 'neutral.500'
      }
      borderWidth={1}
      p="5px"
      borderRadius={5}
      borderStyle="solid"
      width={32}
      height={32}
    >
      {service.iconUrl ? <SvgInline url={service.iconUrl} /> : <General />}
    </Box>

    <Box ml={2}>
      <span>{service.name}</span>
    </Box>
  </Button>
)

export const Projects: React.FC<
  ProjectsModule & {
    services: Service[]
  }
> = ({ text, items, services }) => {
  console.log(items, services)
  const projects = items.filter(
    p => p.service && p.previewImage && p.previewImage.asset
  )
  services = services.filter(service =>
    projects.some(p => p.service._id == service._id)
  )

  const allService = {
    name: 'Alle',
    iconUrl: null,
    _id: null
  }

  const [selectedService, setSelectedService] = useState<Partial<Service>>(
    allService
  )

  const [filteredProjects, setFilteredProjects] = useState<Project[]>([])

  useEffect(() => {
    setFilteredProjects(
      projects.filter(p =>
        selectedService._id == null
          ? true
          : p.service._id == selectedService._id
      )
    )
  }, [selectedService])

  return (
    <Box mb={7}>
      <Box
        boxShadow={[null, null, null, 0]}
        bg="white"
        css={{
          position: 'sticky',
          top: 0,
          zIndex: 2
        }}
      >
        <Container px={5} py={3}>
          <Box display={['none', null, null, 'block']}>
            <Flex
              justifyContent="center"
              width={1}
              flexDirection={['column', null, 'row']}
            >
              <SelectButton
                service={{
                  _id: null,
                  name: 'Alle',
                  iconUrl: null
                }}
                selectedService={selectedService}
                setSelectedService={setSelectedService}
              >
                Alle
              </SelectButton>

              {services.map(service => (
                <SelectButton
                  key={service._id}
                  service={service}
                  selectedService={selectedService}
                  setSelectedService={setSelectedService}
                />
              ))}
            </Flex>
          </Box>

          <Box display={['block', null, null, 'none']} fontFamily="default">
            <Select
              isSearchable={false}
              onChange={({ value }) => {
                value == null
                  ? setSelectedService(allService)
                  : setSelectedService(services.find(s => s._id == value))
              }}
              styles={{
                control: defaults => ({
                  ...defaults,
                  padding: '15px 5px',
                  border: '1px solid #fff',
                  borderRadius: 'none',
                  boxShadow: theme.shadows[0]
                }),
                input: defaults => defaults
              }}
              options={[
                {
                  _id: null,
                  name: 'Alle',
                  iconUrl: services[0].iconUrl
                },
                ...services
              ].map(service => ({
                label: service.name,
                value: service._id
              }))}
            />
          </Box>
        </Container>
      </Box>

      <Box px={5}>
        <Container>
          <MainHeadingWithDescription
            title={text.title}
            description={text.description}
          />

          <Box m={-3}>
            <FlipMove
              style={{ display: 'flex', flexWrap: 'wrap' }}
              appearAnimation="accordionVertical"
              staggerDelayBy={20}
            >
              {filteredProjects.map(project => {
                return (
                  <Box
                    key={project._id}
                    width={[1, null, 1 / 2, 1 / 3]}
                    height="21rem"
                    p={3}
                  >
                    <Link to={`/referenzen/${project.slug}`}>
                      <Box
                        height="100%"
                        position="relative"
                        width={1}
                        boxShadow={0}
                      >
                        <Box
                          position="absolute"
                          bottom={0}
                          left={0}
                          width={1}
                          zIndex={1}
                          p={4}
                        >
                          <Heading2
                            fontFamily="default"
                            m={0}
                            textColor="white"
                            fontWeight={600}
                            fontSize={0}
                            mb={1}
                          >
                            {project.service.name}
                          </Heading2>
                          <Heading1
                            fontFamily="default"
                            lineHeight={1}
                            m={0}
                            textColor="white"
                            fontWeight={600}
                            fontSize={3}
                          >
                            {project.name}
                          </Heading1>
                        </Box>

                        <Box
                          position="absolute"
                          bottom={0}
                          left={0}
                          width={1}
                          height="100%"
                          css={{
                            background: `linear-gradient(to top, rgba(0, 0, 0, 0.5) 10%, transparent)`
                          }}
                        />

                        <Img
                          style={{ height: '100%', width: '100%', zIndex: -1 }}
                          objectFit="cover"
                          fluid={getFluidGatsbyImage(
                            project.previewImage.asset,
                            { maxWidth: 600 },
                            sanity.config
                          )}
                          alt={project.previewImage.caption}
                        />
                      </Box>
                    </Link>
                  </Box>
                )
              })}
            </FlipMove>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}
