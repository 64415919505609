import React from 'react'
import { HistoryModule, HistoryEntry } from '../lib/sanity-types'
import {
  Box,
  MainHeading,
  Container,
  Flex,
  Text,
  SpaceChildren
} from '@sitewerk/juraholzbau-design-system'
import { BasicRichText } from './basic-rich-text'
import Img from 'gatsby-image/withIEPolyfill'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import * as sanity from '../lib/sanity'
import { FadeInContainer } from './fade-in-container'

const HistoryItem: React.FC<HistoryEntry & { index: number }> = ({
  content,
  image,
  index,
  title
}) => (
  <FadeInContainer>
    <Box
      alignItems="center"
      display={['block', null, 'flex']}
      flexDirection={index % 2 == 0 ? 'row' : 'row-reverse'}
    >
      {image && image.asset && (
        <Box
          flex={1}
          pr={index % 2 == 0 ? [0, null, 4] : null}
          pl={index % 2 != 0 ? [0, null, 4] : null}
        >
          <Box
            boxShadow={image && image.asset ? 0 : null}
            height={[200, null, 'auto']}
            css={{ maxHeight: '100%' }}
          >
            <Img
              style={{ maxHeight: '100%' }}
              imgStyle={{ objectFit: 'cover', width: '100%' }}
              fluid={getFluidGatsbyImage(
                image.asset,
                { maxWidth: 720 },
                sanity.config
              )}
              alt={image.caption}
            />
          </Box>
        </Box>
      )}

      <Flex
        flex="2"
        justifyContent="center"
        alignItems="center"
        mb={[0, null, 7]}
        mt={[0, null, 7]}
      >
        <Box maxWidth="60ch">
          <Text
            as="h3"
            m={0}
            mt={image && image.asset ? [4, null, 0] : null}
            mb={2}
            fontSize={[3, null, 4]}
            textColor="neutral.200"
            lineHeight={1}
            fontWeight="bold"
          >
            {title}
          </Text>

          <BasicRichText
            blocks={content}
            TextElement={props => {
              // @ts-ignore
              return (
                <Text
                  m={0}
                  textColor="neutral.200"
                  fontWeight={300}
                  fontSize={2}
                  lineHeight={2}
                  {...props}
                />
              )
            }}
          />
        </Box>
      </Flex>
    </Box>
  </FadeInContainer>
)

export const History: React.FC<HistoryModule> = ({ text, history }) => (
  <Container px={4} mb={7}>
    <FadeInContainer>
      <MainHeading>{text.title}</MainHeading>
    </FadeInContainer>

    <SpaceChildren size={6} direction="top">
      {history.map((item, i) => (
        <HistoryItem index={i} key={item._key} {...item} />
      ))}
    </SpaceChildren>
  </Container>
)
